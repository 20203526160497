import { useState } from 'react';
import { isSameDay, isSameMonth, getYear, isBefore } from 'date-fns';
// utils
import { fDate } from '../../utils/formatTime';
export default function useDateRangePicker(start, end) {
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var _b = useState(end), endDate = _b[0], setEndDate = _b[1];
    var _c = useState(start), startDate = _c[0], setStartDate = _c[1];
    var isError = (startDate && endDate && isBefore(new Date(endDate), new Date(startDate))) || false;
    var currentYear = new Date().getFullYear();
    var startDateYear = startDate ? getYear(startDate) : null;
    var endDateYear = endDate ? getYear(endDate) : null;
    var isCurrentYear = currentYear === startDateYear && currentYear === endDateYear;
    var isSameDays = startDate && endDate ? isSameDay(new Date(startDate), new Date(endDate)) : false;
    var isSameMonths = startDate && endDate ? isSameMonth(new Date(startDate), new Date(endDate)) : false;
    var standardLabel = "".concat(fDate(startDate), " - ").concat(fDate(endDate));
    var getShortLabel = function () {
        if (isCurrentYear) {
            if (isSameMonths) {
                if (isSameDays) {
                    return fDate(endDate, 'dd MMM yy');
                }
                return "".concat(fDate(startDate, 'dd'), " - ").concat(fDate(endDate, 'dd MMM yy'));
            }
            return "".concat(fDate(startDate, 'dd MMM'), " - ").concat(fDate(endDate, 'dd MMM yy'));
        }
        return "".concat(fDate(startDate, 'dd MMM yy'), " - ").concat(fDate(endDate, 'dd MMM yy'));
    };
    var onChangeStartDate = function (newValue) {
        setStartDate(newValue);
    };
    var onChangeEndDate = function (newValue) {
        if (isError) {
            setEndDate(null);
        }
        setEndDate(newValue);
    };
    var onReset = function () {
        setStartDate(null);
        setEndDate(null);
    };
    return {
        startDate: startDate,
        endDate: endDate,
        onChangeStartDate: onChangeStartDate,
        onChangeEndDate: onChangeEndDate,
        //
        open: open,
        onOpen: function () { return setOpen(true); },
        onClose: function () { return setOpen(false); },
        onReset: onReset,
        //
        isSelected: !!startDate && !!endDate,
        isError: isError,
        //
        label: standardLabel || '',
        shortLabel: getShortLabel() || '',
        //
        setStartDate: setStartDate,
        setEndDate: setEndDate,
    };
}
